import "./ZipSelector.scss";
import { useState, useEffect, useRef } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { AiOutlineClose } from 'react-icons/ai';
import CS from '@root/i18n/cs';


import { getZIPList, ZipItem } from "@root/service/stoneService";

export default function ZipSelector({ ...rest }: any) {
    const { defaultZip } = rest;

    const zipSearch = useRef<any>(null);

    const itemsLimit = 6;
    const [zip, setZip] = useState(defaultZip || '');
    const [singlePick] = useState(rest.singlePick || '');
    const [initialZip] = useState(defaultZip || '');
    const [selectedZipList, setSelectedZIPList] = useState<ZipItem[]>([]);

    // data source of zip codes

    const disabled = singlePick && selectedZipList.length || rest.disabled;

    const [dataSource, setDataSource] = useState<any>();
    const [zipListFiltered, setZipListFiltered] = useState<any>();
    const [zipListFilteredUi, setZipListFilteredUi] = useState<any>();


    // useEffect(() => {
    //     setZip(defaultZip);
    //     // setInitialZip(defaultZip);
    // }, [defaultZip]);

    useEffect(() => {
        (async () => {
            let zipList: ZipItem[] = rest.items;
            if (!zipList) {
                zipList = await getZIPList();
            }
            setDataSource(zipList);
        })();
    }, []);
    
    useEffect(() => {
        if (dataSource) {
            const zipListFilteredResp = dataSource
                .filter((item: any) => (selectedZipList.indexOf(item) === -1)) 
                .filter((item: ZipItem) => {
                    const match = 
                        (zip.match(/^\d/) && zip.replace(/[^0-9.]/g, '').length > 0 && item.zip.indexOf(zip.replace(/[^0-9.]/g, '')) >= 0) ||
                        (item.name.toLowerCase().indexOf(zip.toLowerCase()) >= 0) ||
                        item.district.toLowerCase().indexOf(zip.toLowerCase()) >= 0;
    
                    return zip.length && item.zip ? match : true;
                });
    
            const zipListFilteredUiResult = (!zip.length || (singlePick && selectedZipList.length)) && !rest.displayAll ? [] : zipListFilteredResp
                .filter((item: any, index: number) => (index < itemsLimit && selectedZipList.indexOf(item) === -1));
            
            setZipListFiltered(zipListFilteredResp);
            setZipListFilteredUi(zipListFilteredUiResult);
    
            if (initialZip && zipListFilteredResp.length === 1) {
                zipListSelectHandle(null, zipListFilteredResp[0]);
            }
        }
    }, [zip, dataSource, selectedZipList]);

    // useEffect(() => {
    // }, [initialZip, initialZip]);
    
    // initial selected zip list
    useEffect(() => {
        if (rest.initialSelectedZIPList) {
            setSelectedZIPList(rest.initialSelectedZIPList);
        }
    }, [rest.initialSelectedZIPList]);

    function zipChangeHandler(e: any) {
        setZip(e.target.value);
    }

    // @ts-ignore
    function zipListSelectHandle(e: any, item: ZipItem) {
        if (!disabled) {
            const data = [...selectedZipList, item];
            // if (singlePick) {
            //     setZip(item.zip);
            // }
            setSelectedZIPList(data);
            rest.changed(data);
        }
    }

    function clearZipCodeHandler() {
        setZip('');
        zipSearch.current?.focus();
    }

    return (
        <div className="card zip-selector">
            <div className="card-body">
                {!rest.ignoreFilter && disabled &&
                    <div className="mb-3 zip-code">
                        {CS.components.zipSeletor.title} 
                    </div>
                }

                {!rest.ignoreFilter && !disabled &&
                    <div className="mb-3 zip-code">
                        <label htmlFor="psc" className="card-title">
                            {rest.title || CS.components.zipSeletor.serchInstruction}
                        </label>
                        <input ref={zipSearch} disabled={disabled} value={zip} autoComplete="off" onChange={zipChangeHandler} type="text" className="form-control" id="psc" placeholder={CS.components.zipSeletor.title}  />
                        {zip.length > 0 &&
                            <AiOutlineClose className="clear-button" onClick={clearZipCodeHandler}/>
                        }
                    </div>
                }

                {zipListFilteredUi?.length > 0 &&
                    <>
                        <ul className="list-group main-list">
                            {zipListFilteredUi
                                .map((item: ZipItem, index: number) => {
                                    return (
                                        <li key={index} 
                                            onClick={(e) => zipListSelectHandle(e, item)} 
                                            className={"list-group-item" + (disabled ? " disabled" : "")}><b>{item.zip}</b> {item.name} - {item.district}</li>
                                    )
                                })}

                            {!zip && (zipListFiltered.length === 0 && !singlePick && rest.items === undefined) &&
                                <li className="list-group-item list-group-item-warning">{CS.components.zipSeletor.messages.noMoreResults}</li>
                            }
                            {zip.length > 0 && zipListFiltered.length !== 0 &&
                                <li className="list-group-item list-group-item-light">{CS.components.zipSeletor.messages.resultCount}: {zipListFiltered.length}</li>
                            }
                            {zipListFiltered.length === 0 && !singlePick && rest.items === undefined &&
                                <li className="list-group-item list-group-item-danger">
                                    {CS.components.zipSeletor.messages.emptyResult} 
                                    {/* {Number(zip) ? 
                                        <>
                                            &nbsp;<input className="form-check-input" type="checkbox" name="owner"/> Oznacit jako chybejici misto
                                        </>
                                    : ''} */}
                                </li>
                            }

                            {rest.items !== undefined && !rest.items.length &&
                                <li className="list-group-item list-group-item-danger">{CS.components.zipSeletor.messages.emptySource}</li>
                            }
                        </ul>

                        <br />
                    </>
                }

                {selectedZipList.map((item, index) =>
                    <button 
                        key={index}
                        onClick={() => {
                            const data = [...selectedZipList];

                            data.splice(data.indexOf(item), 1);
                            setSelectedZIPList(data);
                            // setZip('');
                            rest.changed(data);
                        }}
                        type="button" className="btn btn-success btn-space p-1">{rest.formatSelected ? rest.formatSelected(item) : item.zip} <IoMdCloseCircle /></button>
                )}

                {selectedZipList.length > 15 &&
                    <>
                        <br />
                        <br />
                        <li className="list-group-item list-group-item-warning">{CS.components.zipSeletor.messages.tooManyZipCodes} 🤔</li>
                    </>
                }
            </div>
        </div>
    );
}