export default {
    errorCodes: {
        101: 'Uživatel namá uživatelské práva pro použití danné funkcionality.'
    },
    labels: {
        reject: 'Odmítnout',
        approve: 'Schvaluji',
        cancel: 'Zrušit',
        close: 'Zavřít',
        edit: 'Upravit',
        remove: 'Odstranit',
        ok: 'Ok',
        save: 'Uložit',
        saveChanges: 'Uložit změny',
        stones: 'Kamínky',
        register: 'Registrovat',
        login: 'Přihlásit',
        forgotPassword: 'zapomenuté heslo',
        password: 'Heslo',
        email: 'Emailová adresa',
        submit: 'Odeslat',
        confirm: 'Potvrdit',
        verificationCode: 'Potvrzovaci kód',
        firstLogin: 'První přihlášení',
        sendReport: 'Odeslat',
        info: 'Info',
        error: 'Chyba'
    },
    messages: {
        warning: {
            filterNotFound: 'Pro zadaný filtr neexistuje výsledek.',
            emptyApproveList: 'Není potřeba schválit žádný kamínek.',
        }
    },
    pages: {
        login: {            
            choices: 'Pro přihlášení nebo registraci si vyberte jednu z následujících možností.',
            contactAdmin: 'Pokud se vám nedaří přihlásit, kontaktujte prosím správce',
            byFacebook: 'přes Facebook',
            byGoogle: 'přes Google',
            userName: 'Uživatelské jméno',
            email: 'Uživatelské jméno',
            submit: 'Přihlásit se',
            forgotPassword: 'zapomenuté heslo',
            messages: {
                error: {
                    differentGoogleProvider: 'Už jste zaregistrovaný přes Google. Přihlaste se pod tímto účtem.',
                    differentFacebookProvider: 'Už jste zaregistrovaný přes Facebook. Přihlaste se pod tímto účtem.',
                    alreadyRegister: 'Uživatel se stejným jménem je už registrovaný.',
                    notApprowedUser: 'Uživatel není ověřen. Klikněte na odkaz, který Vám přišel na email.',
                    notAuthorizedException: 'Uživatel není registrován nebo byl odstraněn.',
                    userNotFoundException: 'Uživatel není registrován.',
                    invalidLogin: 'Špatně zadané jméno nebo heslo.',
                    unknownError: 'Došlo k neočekávané chybě, zkuste akci provést znovu.'
                }
            },
            usersSone: {
                title: 'Galerie uživatelských kamínků.',
                description: 'Zde se nachází ukázka nedávno přidaných kamínků. Po přihlášení se Vám jich zobrazí mnohem více ;)'
            }
        },
        register: {
            title: 'Registrace',
            userName: 'Uživatelské jméno',
            email: 'Emailová adresa',
            password: 'Heslo',
            checkPassword: 'Heslo pro ověření',
            submit: 'Registrovat',
            messages: {
                info: {
                    part1: 'Právě jsme vám zaslali uvítací e-mail obsahující odkaz k potvrzení vlastnictví e-mailové adresy. Kliknutím na tento odkaz aktivujete svůj uživatelský účet a získáte přístup ke všem našim službám a funkcím.',
                    part2: 'Děkujeme vám za registraci a těšíme se na spolupráci s vámi. Pokud byste potřebovali jakoukoliv pomoc, neváhejte nás kontaktovat prostřednictvím naší e-mailové adresy ',
                    email: 'info@kaminkovnik.cz'
                },
                error: {
                    differentGoogleProvider: 'Už jste zaregistrovaný přes Google. Přihlaste se pod tímto účtem.',
                    differentFacebookProvider: 'Už jste zaregistrovaný přes Facebook. Přihlaste se pod tímto účtem.',
                    unknownError: 'Došlo k neočekávané chybě, zkuste akci provést znovu.'
                }
            },
            usersSone: {
                title: 'Galerie uživatelských kamínků.',
                description: 'Zde je ukázka nedávno přidaných kamínků.'
            },
            terms: {
                agreeWith: 'Souhlasím se',
                part1: 'Všeobecnými podmínkami a zásadami ochrany osobních údajů.'
            },
            gdpr: {
                agreeWith: 'Souhlasím se',
                part1: 'zpracováním mých osobních údajů podle GDPR</NavLink> pro účely registrace a použití služeb platformy.',
                part2: 'pro účely registrace a použití služeb platformy.',
                part3: 'Moje údaje budou použity pro správu účtu a komunikaci. Mám právo na přístup, opravu a výmaz údajů, stejně jako na další práva uvedená v ',
                part4: 'Zásadách ochrany osobních údajů',
                part5: '',
                part6: '',
                part7: '',
            }
        },
        forgotPassword: {
            title: 'Zapomenuté heslo',
            titleFinish: 'Zapomenuté heslo dokončení',
            newPassword: 'Nové heslo',
            checkPassword: 'Heslo pro ověření',
            update: 'Změnit heslo',
            askAgain: 'Znovu požádat za',
            messages: {
                complete: {
                    part1: 'Právě jsme Vám na Váš e-mail odeslali ověřovací kód, který Vám umožní prokázat pravost Vašeho účtu. Prosím, zadejte tento kód níže a vytvořte nové heslo pro přihlášení do systému. Děkujeme!'
                },
                error: {
                    validateEmail: 'Prosím ověřte, že jste zadali spravý email.',
                    unknownError: 'Došlo k neočekávané chybě, zkuste akci provést znovu.'
                }
            }
        },
        profile: {
            welcome: {
                title: 'Vítej v kamínkovníku.',
                description: 'Už jen pár malých informací o Vás a můžete plnohodnotně používat kamínkovník. Před prvním použitím kamínkovníku potřebujeme o Vás znát pár základních informací, které budou sloužit k lepšímu fungování aplikace.'
            },
            avatar: {
                title: 'Profilový obrázek',
                uploadImage: 'Nahrát Obrázek',
                saveImage: 'Uložit Obrázek',
                zoom: 'Přiblížení',
                rotate: 'Rotace',
                edit: 'Upravit profilový obrázek'
            },
            userInfo: {
                title: 'Uživatelské jméno (pod kterým se zobrazují vaše příspěvky)',
                email: 'E-mail',
                placeHolder: 'Uživatelské jméno'
            },
            yourZip: 'Vyhledejte vaše PSČ. Tento údaj bude sloužit jako výchozí hodnota při filtrování kamínků apod.',
            messages: {
                warning: {
                    sameUser: 'Uživatel se stejným uživatelským jménem už existuje. Dva uživatelé se stejným jménem nemohou existovat.',
                    zipMandatory: 'PSČ je povinný údaj!',
                    minNameLength: 'Minimalni delka uzivatelske jmeno jsou 4 znaky.'
                },
                error: {
                    updateError: 'Nedovedu upravit uživatele. Zkuste to prosím znovu nebo kontaktujte správce.'
                },
                info: {
                    userRemoved: 'Váš uživatelský profil byl úspěšně odstraněn.'
                }
            },
            export: {
                title: 'Exportovat uživatelské data'
            },

        },
        home: {
            yourStones: 'Tvoje kamínky',
            newMessages: 'Nove zpravy',
            privateStones: 'Soukromé kamínky',
            rejectedStones: 'Odmítnuté kamínky',
            usersSone: {
                title: 'Galerie uživatelských kamínků.',
                description: 'Zde je ukázka nedávno přidaných kamínků.'
            }
        },
        stones: {
            filter: {
                headers: {
                    rejectedStones: 'Odmítnuté kamínky',
                    myStones: 'Moje kamínky',
                    privateStones: 'Soukromé kamínky',
                    search: {
                        header: 'Vyhledávání / Filtrování',
                        title: 'Najdi si svůj kamínek.',
                        instructions: 'Přejete si najít váš kamínek, právě v této části si můžete upravit vyhledávací podmínky pro nalezení vašeho kamínku.'
                    }
                }
            },
            showAll: 'Zobrazit všechny veřejné kamínky',
            clearSearch: 'Vymazat vyhledavani',
            search: 'Vyhledat',            
            messages: {
                warning: {
                    emptySearchResult: 'Pro zadaný filtr neexistuje výsledek.'
                }
            }
        },
        stoneDetail: {
            title: 'Detail kamínku',
            info: {
                aboutPointers: 'V tomto příspěvku jsou označené kamínky, a pokud najedete myší nad něj nebo kliknete do něj, zobrazí se přesné umístění s dodatečnými informacemi. Pokud ještě nějaký kamínek není označen a jedná se o Vámi malovaný kamínek, můžete ho označit jednoduše kliknutím na něj.',
                title: 'Informace o kamínku',
                stoneCount: 'Počet nalezených kamínků'
            },
            userActions: 'Uživatelské akce',
            comments: 'Komentáře'
        },
        addNewStone: {
            header: 'Přidání kamínku!',
            owner: {
                unknown: {
                    title: 'Nalezený kamínek',
                    descripton: 'Vámi nalezený kamínek.',
                    wizard: {
                        instructions: 'Gratulujeme! Našli jste nový kamínek se kterým se chcete pochlubit? Prosím přečtěte si instrukce, abyste se ujistili, že Váš nalezený kamínek nalezne také jeho autor.',
                        instructionFilter: 'Pomocí vyhledávacího filtru můžete zúžit a zpřesnit seznam vyhledávání dle PSČ nebo i města. Můžete zadat jedno i více PSČ.',
                        zipInfo: 'Vyhledejte všechny PSČ, které se nachází na kamínku/kamíncích.',
                        position: {
                            title: 'Klikněte na mapu. Nastavte kurzor do přibližného místa, kde jste kamínek nalezli.',
                            error: {
                                unknown: 'Nedovedeme automaticky detekovat vaši polohu, ujistěte se, že toto nastavení máte aktivní. Umístěte kurzor ručně.'
                            }
                        }

                    }
                },
                you: {
                    title: 'Vlastní kamínek',
                    descripton: 'kamínek vytvořený Vámi. Kamínek bude viditelný pouze Vámi ve Vaší soukromé sbírce.',
                    wizard: {
                        zipInfo: 'Zadejte PSČ Vašeho kamínku.'
                    }
                }
            },
            images: {
                title: 'Obrázky',
                wizard: {
                    title: 'Klikněte na tlačítko',
                    button: 'Přidat obrázek',
                    instructions: 'a objeví se vám možnost výběru obrázku. Ke každému obrázku máte možnost přiřadit PSČ z dříve vytvořeného seznamu.'
                },
                addImage: 'Přidat obrázek',
            },
            note: 'Poznámka (volitelná, zbývá % znaků)',
            stoneCount: 'Počet kamínků (celkový počet kamínků, které jsou ve vašem příspěvku) *',
            newStone: 'Nový kamínek',
            messages: {
                warning: {
                    maxImages: 'Maximálně můžete přidat 20 obrázků.',
                    ensureStoneCount: 'Ověřte zda-li sedí počet kamínků. Zadali jste {} PSČ kód/kódů, ale jen {} kamínek/kamínku'
                },
                error: {
                    createIssue: 'Při pokusu o přidání nového záznamu došlo k neočekávané chybě. Prosím, zopakujte tuto akci. Ujistěte se, že máte stabilní a plně funkční připojení k internetu. Pokud se chyba při ukládání nadále vyskytuje, zkuste vytvoření nového záznamu později.'
                }
            },
            success: {
                title: 'Úspěch!',
                instructions: 'Právě jste vytvořili kamínek. Nyní prosím vyčkejte než bude váš příspěvek schválen administrátory a objeví se ve veřejné databázi.',
                why: 'Tímto krokem se snažíme předejít vytvoření nevhodného příspěvku či jinak závadného obsahu.'
            }
        },
        approveStone: {
            header: 'Schvalování kamínků',
            description: 'Pro zařazení nových příspěvků do veřejné časti je nutné schválit následující kamínky.',
            totalCountToApprove: 'Celkem ke schválení',
            updateStoneCount: 'Upravit počet kamínků',
            stoneParameters: 'Parametry kamínků',
            stoneCount: 'Počet kamínků',
            rejectDialog: {
                title: 'Odmítnutí kamínku',
                description: 'Napište zde důvod pro odmítnutí kamínku.'
            }
        },
        inpsectStone: {
            header: 'Dokončení kamínků',
            description: 'V této části je potřeba doplnit dodatečné informace, aby byla zajištěna konzistence příspěvků. Prosím, označte všechny kamínky na obrázcích. Pokud se jedná o kamínek s přední i zadní stranou, postačí označit pouze přední stranu.',
            totalCountToApprove: 'Celkem ke dokončení',
            updateStoneCount: 'Upravit počet kamínků',
            stoneParameters: 'Parametry kamínků',
            stoneCount: 'Počet kamínků',
            rejectDialog: {
                title: 'Odmítnutí kamínku',
                description: 'Napište zde důvod pro odmítnutí kamínku.'
            }
        },
        editStone: {
            header: 'Editace kamínků',
            description: 'V této části aplikace máte možnost upravit některé parametry příspěvku. Po dokončení úprav můžete stisknout tlačítko "Uložit", abyste potvrdili provedené změny. Pokud nejste spokojeni s úpravami, můžete kdykoliv stránku znovu načíst nebo opustit bez uložení, čímž se žádné provedené změny neprojeví.',
        },
        report: {
            reportError: 'Nahlášení chyby',
            reportNews: 'Reportovani novinky',
            news: {
                header: 'Reportovani novinky',
                afterSend: 'Novinka byla vytvorena.'
            },
            error: {
                header: 'Nahlášení chyby',
                instructions: `
                    I mistr tesař se občas utne a i Kamínkovník je na tom bohužel někdy podobně. 
                    Pokud jste narazili na nějakou chybu nebo problém při používání naší aplikace, rádi bychom se omluvili za způsobené nepříjemnosti. 
                    Na této stránce můžete nahlásit jakékoliv chyby, abychom je co nejdříve mohli opravit a zlepšit tak vaše uživatelské zkušenosti. 
                    Budeme vás průběžně informovat o stavu opravy v oznamovacím panelu. 
                    Děkujeme vám za trpělivost a pochopení. S pozdravem, tým kaminkovníku.
                `,
                questions: {
                    title: 'Rádi bychom vám pomohli vyřešit jakýkoliv problém, na který jste narazili při používání naší aplikace. Abyste nám mohli poskytnout co nejvíce užitečných informací pro rychlé a efektivní řešení, prosíme vás, abyste nám poskytli následující údaje:',
                    q1: 'Popište, jaký problém nebo chybu jste zažili při používání aplikace.',
                    q2: 'Uveďte, na jakém zařízení (Mobil, PC) jste aplikaci používali.',
                    q3: 'Uveďte, jaký operační systém (Windows, Android, Apple) máte nainstalován na vašem zařízení.',
                    final: 'Děkujeme Vám za vaši spolupráci a těšíme se, že vám co nejdříve pomůžeme vyřešit problém.'
                },
                afterSend: 'Děkujeme vám za nahlášení problému. Vaše zpětná vazba je pro nás velmi důležitá a my se budeme snažit vyřešit problém co nejdříve. Naši technici budou na této záležitosti pracovat, aby byla co nejdříve vyřešena. Budeme vás průběžně informovat o průběhu a stavu opravy v našem oznamovacím panelu. Ještě jednou vám děkujeme za trpělivost a pochopení.'
            }
        },
        about: {
            title: 'Vítej v Kamínkovníku',
            description: {
                part1: `Vítejte ve světě putovních kamínků! Naše aplikace vám umožní sdílet své zážitky z hledání a objevování kamínků 
                    v různých koutech světa. Můžete s ní snadno sdílet informace o nalezených kamíncích s ostatními uživateli a inspirovat 
                    tak další, aby se vypravili na putování a objevování malovaných kamínků.`,
                part2: `Začátek je snadný - stačí vložit fotografii a psč nalezeného kamínku a sdílet ji s ostatními uživateli.`,
                part3: `Ať už jste začátečník nebo zkušený lovec kamínků, naše aplikace Vám umožní prozkoumat nová místa, najít 
                    skryté krásy a navázat nová přátelství s lidmi, kteří sdílejí stejnou vášeň.`,
                part4: `Tak neváhejte a přidejte se k nám a klikněte na`,
            },
            ourTeam: 'Náš tým',
            moto: 'Díky našemu týmu se kamínkovník stal přátelským místem plným inspirace a potěšení z sdílení objevů kamínků. Udržujeme kvalitu příspěvků na profesionální úrovni a zajišťujeme jedinečnou a cennou atmosféru bez cenzury obsahu, založenou na přátelské a respektující spolupráci.',
            members: {
                lenka: {
                    title: 'Lenka',
                    content: 'Jmenuji se Lenka, mám 2 děti a žiju v Ostravě. K  malování kamínků mě přivedl covid. Začala jsem malovat se synem, ale místo něho to pohltilo mě 🙂 Kamínkovník pro mě a další nadšence programuje můj přítel, který už měl plné zuby mého věčného remcání, že fb nefunguje jak má. Vaše dotazy ráda zodpovím, co nebudu vědět, přetlumočím výš.'
                },
                jana: {
                    title: 'Jana',
                    content: 'Jmenuji se Jana a bydlím v Ostravě, mám 3 děti. Malovat kamínky jsem začala asi před 4 lety, moc se mi líbil nápad putovních kamínků. Vždy jsem ráda malovala a kamínky tomu dávají nejaký účel. Navíc je to dobrá zábava i pro děti, jak malování, tak hlavně roznášení a hledání. Na kamínky nejradši maluji fixami, ale občas kombinuji i barvy. Motivy hodně střídám, inspiraci hledám hlavně na pinterestu.'
                },
                martina: {
                    title: 'Martina',
                    content: 'Ahoj jmenuji se Martina,na kamínky jsem přišla v roce 2020 a byla to láska na první pohled.Téměř okamžitě jsme s dětmi jeli koupit fixy a lak a mohlo se začít.Malování je pro mě neskutečný relax.A hledání kamínků radost pro celou mou rodinku.Tak šup, na nic nečekejte a pusťte se také do toho.'
                }
            }
        }

    },
    components: {
        mainMenu: {
            header: 'Kamínkovník',
            home: 'Domů',
            loginRegister: 'Přihlásit / Registrovat',
            stones: 'Kamínky',
            approveStones: 'Schválit kamínky',
            inspectStones: 'Dokončit kamínky',
            newStone: 'Nový kamínek',
            profile: 'Profil',
            report: 'Nahlásit chybu',
            logout: 'Odhlásit',
            searchHelper: 'PSČ nebo místo',
            search: 'Hledat',
            about: 'O nás',
            articles: 'Články'
        },
        imageItem: {
            title: 'Obrázek č.',
            zipInfo: 'Přiřaďte PSČ, které se nachází na kamínku/kamíncích.',
            remove: 'Odebrat obrázek'
        },
        confirmDialog: {
            title: 'Potvrzeni'
        },
        stoneContent: {
            rejectionReason: 'Důvod odmítnutí',
            rejectedBy: 'odmítnut uživatelem',
            like: 'To se mi libi',
            showDetail: 'zobrazit detail',
            stoneCount: 'počet kamínků',
            comments: {
                title: 'Komentáře'
            },
            edit: 'Editovat',
            delete: 'Smazat',
            deleteText: 'smazat kaminek'
        },
        comments: {
            postComment: 'Napište komentář',
            submit: 'odeslat',
            edit: 'upravit',
            save: 'uložit',
            saveText: 'ulozit komentar',
            delete: 'smazat',
            deleteText: 'smazat komentar',
            cancel: 'zrušit',
            cancelText: 'zrusit upravu komentare'
        },
        passwordRules: {
            minLength: 'Minimalni delka hesla je 8 znaku.',
            requiredNumber: 'Heslo musí obsahovat minimálně jedno číslo.',
            requiredUppercaseLetter: 'Heslo musí obsahovat minimálně jedno velké písmeno.',
            requiredLowercaseLetter: 'Heslo musí obsahovat minimálně jedno malé písmeno.',
            samePassword: 'Heslo pro ověření musi být stejné.'
        },
        captcha: {
            enterResponse: 'Zadejte výsledek matematické úlohy',
            validate: 'Ověření'
        },
        messagePanel: {
            title: 'Upozornění',
            all: 'Všechny',
            comments: 'Komentáře',
            newComment: 'Nový komentář',
            newRating: 'Nové hodnocení',
            newReportError: 'Nové chybové hlášení',
            newReportNews: 'Novinka',
            user: 'Uživatel',
            comment: {
                part1: 'komentoval váš kamínek vytvořený dne',
                part2: 'nalezený v místě'
            },
            rating: {
                title: 'Hodnocení',
                part1: 'hodnotil váš kamínek vytvořený dne',
                part2: 'nalezený v místě',
            },
            reportErrror: {
                title: 'Nahlášené chyby'
            },
            reportNews: {
                title: 'Novinky'
            },
            options: 'možnosti'
        },
        zipSeletor: {
            title: 'PSČ',
            serchInstruction: 'Vyhledej PSČ nebo Město.',
            messages: {
                noMoreResults: 'Pro zobrazení dalších záznamů upravte PSČ filtr.',
                resultCount: 'Počet nalezených zázanmů',
                emptyResult: 'Pro zadané hledání neexistuje výsledek.',
                emptySource: 'Vstupní zdroj dat neobsahuje žádný výběr.',
                tooManyZipCodes: 'Opravdu máte tolik PSČ?'
            }
        }
    },
    templates: {
        removeStone: {
            title: 'Varování',
            content: "Jste si opravdu jisti, že chcete odstranit tento kamínek? Tuto akci nelze vrátit zpět. Prosím, potvrďte smazání kliknutím na tlačítko 'Odstranit'. Pokud si nejste jisti, klikněte na tlačítko 'Zrušit'.",
        },
        adminRemoveStone: {
            title: 'Varování pro Editora',
            content: "Kamínek obsahuje nevhodný obsah, proto si přejete odstranit kamínek? Tuto akci nelze vrátit zpět. Prosím potvrďte smazání kliknutím na tlačítko 'Odstranit'. Pokud si nejste jisti, klikněte na tlačítko 'Zrušit'.",
        },
        removeComment: {
            title: 'Varování',
            content: "Jste si opravdu jisti, že chcete odstranit komentář? Tuto akci nelze vrátit zpět. Prosím potvrďte smazání kliknutím na tlačítko 'Odstranit'. Pokud si nejste jisti, klikněte na tlačítko 'Zrušit'.",
        },
        adminRemoveComment: {
            title: 'Varování pro Editora',
            content: "Komentář obsahuje nevhodný obsah, proto si jej přejete odstranit? Tuto akci nelze vrátit zpět. Prosím potvrďte smazání kliknutím na tlačítko 'Odstranit'. Pokud si nejste jisti, klikněte na tlačítko 'Zrušit'.",
        },
        chooseMyStone: {
            title: 'Označit jako můj kaminek',
            content: "tohle je muj kaminek.",
            edit: {
                title: 'Upravit můj kaminek',
            }
        },
        removeAccount: {
            title: 'Poslední varování',
            content: "Varování: Máte v úmyslu odstranit svůj uživatelský účet. Prosíme, proveďte následující kroky opatrně, neboť tato akce je nevratná. Pokud si nejste jisti, zda chcete pokračovat, můžete kdykoli stisknout tlačítko 'Zrušit'. Pokud jste si jisti, že chcete pokračovat a odstranit svůj uživatelský účet, stiskněte tlačítko 'Odstranit'.",
        },
        missingGps: {
            title: 'Varování',
            content: 
            <>
                Pokoušíte se vytvořit nový kamínek bez určení místa nálezu. Tato informace je užitečná nejen pro autora kamínku, ale i pro ostatní uživatele. 
                <br />
                <br />
                Pokud si přejete přidat místo nálezu, klikněte prosím na tlačítko 'Upravit'. Pokud si přejete vytvořit kamínek bez místa nálezu, stiskněte tlačítko 'Uložit'.
            </>
        }
    }
}