import "./home.scss"
import { SyntheticEvent, useEffect } from "react";
import { useAuth } from '../../contexts/AuthContext';
// import { useStore } from '../../contexts/Store';
// import { HomeData } from "@root/service/stoneService";
import { useNavigate } from 'react-router-dom';
import CS from '@root/i18n/cs';
import UsersGallery from '@root/components/UsersGallery';


export default function Home() {
    const { currentUser } = useAuth();
    // const { appData } = useStore();
    const navigate = useNavigate();
    // const homeData: HomeData = appData.homeData.read();

    // const stoneCount = currentUser.info.user.stoneCount;
    const privateStones =  currentUser.info.user.stoneInfo?.privateCount;
    const rejectedStones = currentUser.info.user.stoneInfo?.rejectedCount;

    useEffect(() => window.scroll({ top: 0, behavior: 'smooth' }) , []);
    
    return (
        <div className="container" id="home">
            <br />            
            <div className="card">
                <ul className="list-group list-group-flush">
                    {/* <li className="list-group-item d-flex justify-content-between align-items-start" 
                        onClick={() => navigate('/stones?today')}>
                        <div className="ms-2 me-auto">
                            Dnes přidáno kamínků.
                        </div>
                        <span className="badge bg-primary rounded-pill">{homeData.today}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                            Celkem  kamínků.
                        </div>
                        <span className="badge bg-primary rounded-pill">{homeData.total}</span>
                    </li> */}
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                        {currentUser.info.user.stoneCount > 0 && 
                            <a href="#">
                                <div className="ms-2 me-auto" onClick={(e: SyntheticEvent) => {
                                    navigate('/stones/my');
                                    e.preventDefault();
                                }}>
                                    {CS.pages.home.yourStones}
                                </div>
                            </a>
                        }

                        {currentUser.info.user.stoneCount === 0 && 
                            <div className="ms-2 me-auto">
                                {CS.pages.home.yourStones}
                            </div>
                        }
                        {/* <span className="badge bg-primary rounded-pill">{stoneCount}</span> */}
                    </li>


                    {currentUser.info.user.newMessages > 0 && 
                        <li className="list-group-item d-flex justify-content-between align-items-start">
                            <a href="#">
                                <div className="ms-2 me-auto" onClick={(e: SyntheticEvent) => {
                                    navigate('/stones/my');
                                    e.preventDefault();
                                }}>
                                    {CS.pages.home.newMessages}
                                </div>
                            </a>
                        </li>
                    }

                    {privateStones > 0 &&
                        <>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <a href="#">
                                    <div className="ms-2 me-auto" onClick={(e: SyntheticEvent) => {
                                            navigate('/stones/private');
                                            e.preventDefault();
                                        }}>
                                        {CS.pages.home.privateStones}
                                    </div>
                                </a>
                                {/* <span className="badge bg-success rounded-pill">{privateStones}</span> */}
                            </li>
                        </>
                    }

                    {rejectedStones > 0 &&
                        <>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <a href="#">
                                    <div className="ms-2 me-auto" onClick={(e: SyntheticEvent) => {
                                            navigate('/stones/rejected');
                                            e.preventDefault();
                                        }}>
                                        {CS.pages.home.rejectedStones}
                                    </div>
                                </a>
                                {/* <span className="badge bg-danger rounded-pill">{rejectedStones}</span> */}
                            </li>
                        </>
                    }
                </ul>
            </div>

            <br />
            <br />
            <div className="card">
                <div className="card-body">
                    <h4>{CS.pages.home.usersSone.title}</h4>
                    <p>{CS.pages.home.usersSone.description}</p>
                </div>
            </div>

            <UsersGallery isPublic={false} />
        </div>
    );
}
