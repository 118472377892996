import "./stone.scss"
import { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CS from '@root/i18n/cs';

import "react-datepicker/dist/react-datepicker.css";

import { useAuth } from '@root/contexts/AuthContext';
import { useStore } from '@root/contexts/Store';
import ZipSelector from '@root/components/ZipSelector';
import StoneContent from '@root/components/StoneContent';

import { getStones, Stone, ZipItem } from "@root/service/stoneService";
import { searchStone, SearchOptions } from '@root/actions/actions';



import { StoneViewState, StoneState } from '@root/helpers/constants';

// import user from "@root/images/user.png";

// const Gallery = require('react-grid-gallery');

function getViewState(viewType: string | undefined) {
    if (viewType === 'my') {
        return StoneViewState.MY_STONES;
    } else if (viewType === 'private') {
        return StoneViewState.PRIVATE;
    } else if (viewType === 'rejected') {
        return StoneViewState.REJECTED;
    } else if (viewType === 'public') {
        return StoneViewState.USERS_STONES;
    }
    return StoneViewState.ALL;
}

export default function Stones() {
    const [busy, setBusy] = useState(true);
    const [stones, setStones] = useState<Stone[]>([]);
    const [lastKey, setLastKey] = useState<string>();
    const [searchZIPList, setSearchZIPList] = useState<ZipItem[]>([]);
    const [searchParams, setSearchParams] = useState<SearchOptions>();
    const [searchVisible, setSearchVisible] = useState(false);

    const { currentUser } = useAuth();
    const { state, dispatch } = useStore();
    const navigate = useNavigate();
    let { viewType, uid } = useParams();
    const location = useLocation();

    //const showMyStones = location.search.toLocaleLowerCase().indexOf('?my') >=0;
    // const [userName, setUserName] = useState(showMyStones ? currentUser.uid : '');

    const [viewState, setViewState] = useState<any>();

    const [defaultZip, setDefaultZip] = useState('');//currentUser.info?.user?.zip);

    useEffect(() => {
        if (defaultZip) {
            console.log('defaultZip', defaultZip);
            setSearchVisible(true);
            window.scroll({ top: 0, behavior: 'smooth' });
            // handleAdvanceSearch();
        }
    }, [defaultZip]);

    useEffect(() => {
        if (viewState) {
            updateFilterStones();
        }
    }, [state, viewState]);

    useEffect(() => {
        setStones([]);
        setViewState(getViewState(viewType));
    }, [location]);

    // handlers
    async function filterStones(search?: SearchOptions, lastKey: any = undefined) {
        setBusy(true);

        return getStones({ search, key: lastKey }).then(resp => {
            setLastKey(resp.lastKey)

            let array = [...stones, ...resp.stones.filter((stone: Stone) => {
                return stones.filter((s: Stone) => s.trackingId === stone.trackingId).length === 0;
            })].sort((a: Stone, b: Stone) => {
                return b.timestamp - a.timestamp;
            });

            setStones(array);
            setBusy(false);
        });
    }

    function updateFilterStones(lastKey?: any) {
        if (!lastKey) {
            setStones([]);
        }
        if ([StoneViewState.MY_STONES].includes(viewState)) {
            const search = { userName: currentUser.uid };
            setSearchParams(search);
            filterStones(search, lastKey);
        } else if ([StoneViewState.PRIVATE].includes(viewState)) {
            // const uid = 'jr4a8QgS77NZo7FIDf3NbNAIp1t1'; // currentUser.uid
            const uid = currentUser.uid
            const search = { state: StoneState.PRIVATE, userName: uid };
            setSearchParams(search);
            filterStones(search, lastKey);
        } else if ([StoneViewState.REJECTED].includes(viewState)) {
            const search = { state: StoneState.REJECT, userName: currentUser.uid };
            setSearchParams(search);
            filterStones(search, lastKey);
        } else if ([StoneViewState.USERS_STONES].includes(viewState)) {
            if (!lastKey) {
                setLastKey('');
            }
            const search = { userName: uid };
            setSearchParams(search);
            filterStones(search, lastKey);
        } else {
            filterStones(state.search, lastKey);
        }
    }

    function clearAdvanceSearch() {
        setStones([]);
        setDefaultZip('');
        setSearchParams(undefined);
        dispatch(searchStone({}));
    }


    function handleAdvanceSearch() {
        setStones([]);

        const search: SearchOptions = {
            zip: searchZIPList.length ? searchZIPList[0].zip : '',
            //userName: currentUser.uid
        };

        setSearchParams(search);
        dispatch(searchStone(search));
        // navigate('/stones?today');
    }

    function handleDisplayAll() {
        navigate('/stones');
        handleAdvanceSearch();
    }

    return (
        <div className="container" id="stones">
            <br />

            <div className="row justify-content-md-center">
                {stones.length > 0 && [StoneViewState.USERS_STONES].includes(viewState) &&
                    <div className="alert alert-info" role="alert">
                        <h3>Veřejné kamínky uživatele {stones[0].user.userName}</h3>
                        <button type="button" className='btn btn-link float-end' onClick={handleDisplayAll}>
                            {CS.pages.stones.showAll}
                        </button>
                    </div>
                }
                {[StoneViewState.REJECTED].includes(viewState) &&
                    <div className="alert alert-danger" role="alert">
                        <h3>{CS.pages.stones.filter.headers.rejectedStones}</h3>
                        <button type="button" className='btn btn-link float-end' onClick={handleDisplayAll}>
                            {CS.pages.stones.showAll}
                        </button>
                    </div>
                }
                {[StoneViewState.MY_STONES].includes(viewState) &&
                    <div className="alert alert-primary" role="alert">
                        <h3>{CS.pages.stones.filter.headers.myStones}</h3>
                        <button type="button" className='btn btn-link float-end' onClick={handleDisplayAll}>
                            {CS.pages.stones.showAll}
                        </button>
                    </div>
                }
                {[StoneViewState.PRIVATE].includes(viewState) &&
                    <div className="alert alert-primary" role="alert">
                        <h3>{CS.pages.stones.filter.headers.privateStones}</h3>

                        <button type="button" className='btn btn-link float-end' onClick={handleDisplayAll}>
                            {CS.pages.stones.showAll}
                        </button>
                    </div>
                }
                {[StoneViewState.ALL].includes(viewState) &&
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button onClick={() => setSearchVisible(true)} className={"accordion-button" + (!searchVisible ? " collapsed" : "")} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    {CS.pages.stones.filter.headers.search.header}
                                </button>
                            </h2>
                            <div id="collapseTwo" className={"accordion-collapse collapse" + (searchVisible ? " show" : "")} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                {searchVisible && 
                                    <div className="accordion-body">
                                        <div className="alert alert-primary" role="alert">
                                            <h4 className="alert-heading">{CS.pages.stones.filter.headers.search.title}</h4>
                                            <p>
                                                {CS.pages.stones.filter.headers.search.instructions}
                                            </p>
                                        </div>

                                        <ZipSelector
                                            defaultZip={defaultZip}
                                            singlePick={true}
                                            formatSelected={(selected: ZipItem) => `${selected.zip} - ${selected.district}`}
                                            changed={(items: ZipItem[]) => {
                                                setSearchZIPList(items);
                                            }}
                                        />

                                        <br />
                                        {/* <select className="form-select" aria-label="Vyhledani podle data">
                                            <option selected>Vyhledani podle data</option>
                                            <option value="1">Předchozí týden</option>
                                            <option value="2">Předchozí dva týdný</option>
                                            <option value="3">Předchozí měsíc</option>
                                            <option value="č">Vlastni ...</option>
                                        </select>

                                        <br />
                                        <DatePicker
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={startDate}
                                            onChange={() => {
                                                debugger;
                                            }}
                                        /> */}


                                        {searchParams && 
                                            <>
                                                <button type="button" className='btn btn-danger' onClick={clearAdvanceSearch}>
                                                    {CS.pages.stones.clearSearch}
                                                </button>&nbsp;
                                            </>
                                        }

                                        <button type="button" className='btn btn-primary' onClick={handleAdvanceSearch}>
                                            {CS.pages.stones.search}
                                        </button>

                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            <br />


            <InfiniteScroll
                dataLength={stones.length}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                next={() => {
                    updateFilterStones(lastKey);
                    // filterStones(searchParams, lastKey);
                }}
                hasMore={lastKey !== undefined}
                loader={null}
                // onScroll={(e: MouseEvent) => {
                //     if (window.scrollY === 0) {
                //         console.log(window.scrollY);
                //         filterStones(undefined, undefined);
                //     }
                // }}
            >
                <div className="row justify-content-md-center">
                    {stones.map((item: Stone, index: any) => 
                        <StoneContent 
                            index={index} 
                            key={index}
                            item={item}
                            stones={stones}
                            setStones={setStones}
                            setDefaultZip={setDefaultZip}
                            setBusy={setBusy}
                            useNativeGallery={true}
                        />
                    )}
                </div>
            </InfiniteScroll>
            {busy &&
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }

            {!busy && stones && !stones.length &&
                <div className="alert alert-primary" role="alert">
                    {CS.pages.stones.messages.warning.emptySearchResult}
                </div>
            }

            <br />
            <br />
        </div>
    );
};
